import {
  findFileByFileId,
  findStopwatchByFtype,
  findType,
  separateReportsToAdd,
  updTFundFundfiles,
  foreignExchangeReportsToAdd,
  foreignExchangeReportsToEdit,
} from "./editApi";
import { ElMessage } from "element-plus";
import { findStockPoolByAcid } from "../../stock-manage/stockApi";
// import {updTFundFundfiles} from "../../stock-manage/stockApi";
import { cloneDeep } from "lodash";
import vPinyin from "@/utils/vue-py";
import axios from "@/axios/globalaxios";
import { findAllTimrTurn } from "../../../teacher-manage/time-turn/timeApi";
import { findForeignFileByFindId } from "@/views/course-manage/upreport-manage/Upreport-State/startApi.js";
export const valueChanges = (value, data) => {
  if (value) data.valueEdit = value;
};
// 根据不同页面 展示不同下拉
export const getTypes = (num, data) => {
  findType().then((res) => {
    if (res.data.code === "200") {
      if (num === 0) {
        data.reportOptions = res.data.data.filter(
          (item) => item.filetype !== 4 && item.filetype !== 5
        );
      } else {
        data.reportOptions = res.data.data;
      }
    }
  });
};

export const upFiles = (num, data, router) => {
  // console.log('router', router);
  // console.log("data.codeList", data.codeList);
  // console.log("data.codeList---1", num);
  // console.log("data.showDrafr---1", data.showDrafr);
  // console.log("data.disabled---1", data.disabled);
  if (!data.weekValue) return ElMessage.warning("请选择周次");
  //当为保存草稿时
  if (!data.showDrafr && data.disabled && num == 1) {
    saveDrafe(data, router);
    return;
  }
  
  //  保存 上传
  let startType = num === 1 ? "1000" : "0";
  if (!data.fileName) return ElMessage.warning("文件名不能为空");
  if (!data.valueEdit) return ElMessage.warning("内容不能为空");
  if (data.codeList.length === 0 && data.showCodeSearch)
    return ElMessage.warning("至少选择一个证券代码");
  data.loadingUp = true;
  let parame = {
    tfundFundfiles: {
      fileId: data.checkType === "1" ? data.pageParame.fileId : null,
      name: data.fileName,
      timeRoundId: data.weekValue,
      // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
      acid: JSON.parse(sessionStorage.getItem("acid")),
      // fmId: JSON.parse(sessionStorage.getItem('classInfo')).courseUserId,
      fmId: JSON.parse(sessionStorage.getItem("courseUserId")),
      filetype: data.fileType,
      files: data.valueEdit,
      fileState: data.fileType === 5 ? data.itemParame.num : startType,
      goodType:0
    },
  };
  // let arr = []
  // if (data.showCodeSearch) arr=
  let typeParame = data.showCodeSearch
    ? {
        tfundStockpools: dealCodeSearch(data.codeList, data),
      }
    : data.fileType === 4
    ? {
        tfundTargetamounts: data.pageParame,
      }
    : data.fileType === 5
    ? {
        acId: JSON.parse(sessionStorage.getItem("acid")),
        // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
        oldFieldId: data.itemParame.fileId,
      }
    : {};
  let newParame = Object.assign(parame, typeParame);
  if (data.financeRadio == '证券') {
    securityUpFiles(data, newParame, num, router);
  } else if (data.financeRadio == "外汇") {
    let foreignExchangeParame = {
      currencyFiles: newParame.tfundFundfiles,
    };
    let foreignExchangeTypeParame = data.showCodeSearch
      ? {
          currencyPools: (data.routePageType == 6 ?data.codeList:dealCodeSearch(data.codeList, data)),
        }
      : data.fileType === 4
      ? {
          currencyTargetAmounts: data.foreignExchangePageParame,
        }
      : data.fileType === 5
      ? {
          acid: JSON.parse(sessionStorage.getItem("acid")),
          // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
          oldFieldId: data.itemParame.fileId,
          lastState: data.itemParame.num,
        }
      : {};
    let foreignExchangeNewParame = Object.assign(
      foreignExchangeParame,
      foreignExchangeTypeParame
    );
    foreignExchangeUpFiles(data, foreignExchangeNewParame, num, router);
  }
  
  setTimeout(() => {
    data.loadingUp = false;
  }, 500);
};

export const securityUpFiles = (data, newParame, num, router) => {
  if (data.checkType !== "1") {
    separateReportsToAdd(newParame).then((res) => {
      if (res.data.code === "200") {
        if (num === 1) {
          ElMessage.success("保存成功");
          router.push({path:'/course/report/draft', query:{ financeRadio: data.financeRadio }});
          if (data.fileType === "6") router.go(-1);
        } else {
          ElMessage.success("上传成功");
          router.push({path:'/course/report/state', query:{ financeRadio: data.financeRadio }});
        }
        if (data.fileType === 5) {dealFileStart(data,data.itemParame)};
        if (data.fileType === 4 || data.fileType === 5) router.go(-1);
      } else {
        ElMessage.error(res.data.msg);
      }
    });
  } else {
    updTFundFundfiles(newParame).then((res) => {
      if (res.data.code === "200") {
        if (num === 1) {
          ElMessage.success("保存成功");
          router.push({path:'/course/report/draft', query:{ financeRadio: data.financeRadio }});
          if (data.fileType === "6") router.go(-1);
        } else {
          ElMessage.success("上传成功");
          router.push({path:'/course/report/state', query:{ financeRadio: data.financeRadio }});
        }
        if (data.fileType === 5) {dealFileStart(data,data.itemParame) ;};
        if (data.fileType === 4 || data.fileType === 5) router.go(-1);
      } else {
        ElMessage.error(res.data.msg);
      }
    });
  }
};

export const foreignExchangeUpFiles = (data, newParame, num, router) => {
  if (data.checkType !== "1") {
    foreignExchangeReportsToAdd(newParame).then((res) => {
      if (res.data.code === "200") {
        if (num === 1) {
          ElMessage.success("保存成功");
          router.push({path:'/course/report/draft',financeRadio:data.financeRadio, query:{ financeRadio: data.financeRadio }});
          if (data.fileType === "6") router.go(-1);
        } else {
          ElMessage.success("上传成功");
          router.push({path:'/course/report/state',financeRadio:data.financeRadio, query:{ financeRadio: data.financeRadio }});
        }
        if (data.fileType === 5) dealFileStart(data,data.itemParame);
        if (data.fileType === 4 || data.fileType === 5) router.go(-1);
      } else {
        ElMessage.error(res.data.msg);
      }
    });
  } else {
    foreignExchangeReportsToEdit(newParame).then((res) => {
      if (res.data.code === "200") {
        if (num === 1) {
          ElMessage.success("保存成功");
          router.push({path:'/course/report/draft',financeRadio:data.financeRadio, query:{ financeRadio: data.financeRadio }});
          if (data.fileType === "6") router.go(-1);
        } else {
          ElMessage.success("上传成功");
          router.push({path:'/course/report/state',financeRadio:data.financeRadio, query:{ financeRadio: data.financeRadio }});
        }
        if (data.fileType === 5) dealFileStart(data,data.itemParame);
        if (data.fileType === 4 || data.fileType === 5) router.go(-1);
      } else {
        ElMessage.error(res.data.msg);
      }
    });
  }
};

export const saveDrafe = (data, router) => {
  // data.pageParame.filetype 不等于1 直接修改报告 ； 等于1 查询码表 上传
  // if (data.pageParame.filetype !== 1){}
  let parame = {
    tfundFundfiles: {
      name: data.fileName,
      timeRoundId: data.weekValue,
      acid: JSON.parse(sessionStorage.getItem("acid")),
      // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
      fmId: JSON.parse(sessionStorage.getItem("courseUserId")),
      // fmId: JSON.parse(sessionStorage.getItem('classInfo')).courseUserId,
      filetype: data.fileType,
      files: data.valueEdit,
      fileId: data.pageParame.fileId,
      goodType:0,
      //修改保存草稿状态，0改为1000
      fileState: "1000",
    },
  };
  if (data.financeRadio == "外汇") {
    let foreignExchangeParame = {
      currencyFiles: parame.tfundFundfiles
    }
    console.log("foreignExchangeParame--草稿", foreignExchangeParame);
    foreignExchangeReportsToEdit(foreignExchangeParame).then(
      (res) => {
        if (res.data.code === "200") {
          if (res.data.msg === "修改成功") router.go(-1);
          ElMessage.warning(res.data.msg);
        }
      }
    );
  } else if (data.financeRadio == "证券") {
    updTFundFundfiles(parame).then((res) => {
      if (res.data.code === "200") {
        if (res.data.msg === "修改成功") router.go(-1);
        ElMessage.warning(res.data.msg);
      }
    });
  }
};

export const dealFileStart = (data,val) => {
  if (val.fileState !== 0) return ElMessage.warning("报告状态只能编辑一次");

  let parame = {
    tfundFundfiles: {
      fileId: val.fileId,
      fileState: val.num,
    },
  };
  console.log("parame--val", val);
  if (data.financeRadio == "外汇") {
    console.log("parame--111", val);
    parame = {}
    parame = {
      currencyFiles: {
        fileId: val.fileId,
        fileState: val.num,
      },
    };
    console.log("parame", parame);
    foreignExchangeReportsToEdit(parame).then((res) => {});
  } else {
    updTFundFundfiles(parame).then((res) => {});
  }
  
};

// 获取全部码表
export const getCodeAlls = (data) => {
  findStopwatchByFtype({ type: null }).then((res) => {
    if (res.data.code === "200") {
      data.codeAll = res.data.data.stopwatches;
    }
  });
};

export const dealCodeSearch = (arrs, data) => {
  let arr = [];
  if (data.financeRadio == "证券") {
    for (let i in arrs) {
      for (let j in data.codeAll) {
        if (arrs[i] === data.codeAll[j].fgsid) {
          arr.push({
            acid: JSON.parse(sessionStorage.getItem("acid")),
            // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
            fileid: null,
            internalsid: data.codeAll[j].fgsid,
            enabled: 0,
            exchange: data.codeAll[j].fjys.toString(),
          });
        }
      }
    }
  } else if (data.financeRadio == "外汇") {
    for (let i in arrs) { 
      arr.push({
        acid: JSON.parse(sessionStorage.getItem("acid")),
        fileId: null,
        currencyId: arrs[i],
        enabled: 0,
      });
    }
  }
  
  return arr;
};

export const reportChanges = (val, data) => {
  // if (val == 3) {
  //   data.biweekly = true
  // } else {
  //   data.biweekly = false;
  // }
  data.showCodeSearch = val === 1 ? true : false;
  if (data.showCodeSearch) getCodeAlls(data);
  data.fileType = val;
};

export const codeSelectChanes = (val, data) => {
  data.codeList = val;
};

export const getCodLists = (data) => {
  let parame = {
    acid: JSON.parse(sessionStorage.getItem("acid")),
    // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
    fileID: data.pageParame.fileId,
    pageSize: 1000,
    page: 1,
  };
  findStockPoolByAcid(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      let arr = [];
      arr = newRes.returnStockPoolInformations.list;
      for (let i in arr) {
        data.codeList.push(arr[i].marketSID);
      }
      data.codeList = cloneDeep(data.codeList);
    }
  });
};

export const getMores = (data) => {
  data.wangEditorShow = false
  console.log("data.codes", data.codes);
  if (data.financeRadio == "外汇") {
    findForeignFileByFindId({ fileId: data.codes[0] }).then((res) => {
      if (res.data.code == "200") {
        data.valueEdit = res.data.data.files;
        data.wangEditorShow = true
        data.codeList = res.data.data.currencyList;
        console.log("data.codeList", data.codeList);
      }
    });
  } else { 
    findFileByFileId({ fieldIds: data.codes }).then((res) => {
      if (res.data.code === "200") {
        let newRes = res.data.data;
        data.moreFile = newRes.tfundFundfiles;
        //获取内容
        data.valueEdit = newRes.tfundFundfiles[0].files;
        data.wangEditorShow = true
      }
    });
  }
  
};

export const checkMoreFiles = (val, data) => {
  data.diaStart = true;
  data.valueFile = val.files;
  data.fileMoreName = val.name;
};

// 查询周次
export const getWeekLists = (data) => {
  let parame = {
    // theQueryIsLessThanTheCurrentTimeRound: 1,
    courseId: sessionStorage.getItem("classId"),
    pageSize: 1000,
    page: 1,
  };
  // if(sessionStorage.getItem('studentCurrentWeek')==='第一周'){
  //    delete parame.theQueryIsLessThanTheCurrentTimeRound
  // }

  findAllTimrTurn(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.weekOption = newRes.pageInfo.list;
    }
  });
};
